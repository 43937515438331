import React, { useState, useEffect } from "react";
import ServicesSection from "../../components/composites/ServicesSection";
import { api } from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceData } from "../../types/service";
import { GalleryData } from "../../types/galleryImages";

const Services = () => {
  const galleryLimit = 100;
  const [servicesRecord, setServicesRecord] = useState<ServiceData>();
  const [galleryRecord, setGalleryRecord] = useState<GalleryData>();
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });

  async function fetchData() {
    const msg = `An error while retrieving services content!`;
    try {
      const params = {
        "filters[visibleOnHomePage][$eq]": true,
        "populate": "blocks,images,logo",
        //"pagination[limit]": serviceLimit,
        "sort": "id:desc"
      };
      const { status, data } = await api("/api/services", "get", params);
      if (status >= 200 && status <= 299) {
        setServicesRecord(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  async function fetchGalleryData() {
    const msg = `An error while retrieving services content!`;
    try {
      const params = {
        "populate": "media,media.file",
        "pagination[limit]": galleryLimit,
        "sort": "id:desc"
      };
      const { status, data } = await api("/api/gallery-images", "get", params);
      if (status >= 200 && status <= 299) {
        setGalleryRecord(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchData();
    fetchGalleryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ServicesSection showGallery={true} servicesRecord={servicesRecord} galleryRecord={galleryRecord} />
  );
};

export default Services;