import styled, { css } from "styled-components";
interface ButtonProps {
  round?: any;
  type?: string;
  primary?: any;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  margin?: string;
  marginTop?: any;
}
const Button = styled.button<ButtonProps>`
  color: ${(props: any) => (props.theme.colors.blue)};
  border-radius: ${(props: any) => (props.round ? '50px' : '50%')};
  padding: 10px;
  margin: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .9rem;
  min-width: 200px;
  margin-top: ${(props: any) => (props.marginTop ? '0px' : '60px')};
  border: 2px solid;
  font-family:  ${props => props.theme.font.families.Poppins};
  cursor: pointer;
  :disabled {
    opacity: 0.4;
  }
  :hover {
    box-shadow: 0 0 10px #ccc;
  }
  ${(props: any) => props.primary && css`
    background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.white)};
    color: ${(props: any) => (props.color ? props.color : props.theme.colors.blue)};
    border-color: ${(props: any) => (props.borderColor ? props.borderColor : props.theme.colors.white)};
  `}
   
  ${(props: any) => props.type == 'secondary' && css`
    color: ${(props: any) => (props.theme.colors.blue)};
    background-color: ${(props: any) => (props.theme.colors.white)};
    border-color:${(props: any) => (props.theme.colors.blue)};
  `}
  ${(props: any) => props.type == 'trans' && css`
    color: ${(props: any) => (props.theme.colors.white)};
    background-color: transparent;
    border-color: ${(props: any) => (props.theme.colors.white)};
  `}
`;

const ButtonMore = styled(Button)`
    font-weight: 500;
    position: relative;
    font-size: 1.3rem;
    min-width: 260px;
    margin-top: 0px;
    cursor: pointer;
    text-transform: capitalize;
    span{
      margin-right:15px;
      font-size: 20px;
    }
`;

const Submit = styled(Button)`

  margin-top: 1.5rem;
  display: block;
  text-align: center;
  font-size: ${(props: any) => (props.theme.fontSize.base)};
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 700;
  height: 3rem;
  white-space: nowrap;
  color:${(props: any) => (props.theme.colors.white)} !important;
  padding: 0.5rem 1rem;
  width:50%;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: pointer;
    background-color: ${(props: any) => (props.theme.colors.brown)};
    box-shadow: none;
    color: ${(props: any) => (props.theme.colors.white)} !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;


export { Button, ButtonMore, Submit };